<template>
  <div>
    <div class="notfind"><img :src="oss + '/manage/media/state/404.png'" alt="" /></div>
  </div>
</template>

<script>
export default {
  name: '404',
  data() {
    return {}
  },
}
</script>
